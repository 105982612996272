





import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drillsv2/atoms/Title.vue'
import ResultGuide from '@/components/modules/drillsv2/atoms/ResultGuide.vue'
import PredictedScoreBar from '@/components/modules/drillsv2/organisms/PredictedScoreBar.vue'
import Correct from '@/components/modules/drillsv2/organisms/Correct.vue'
import Transition from '@/components/modules/drillsv2/organisms/Transition.vue'

import { PAGE_TYPE } from '@/store/modules/DrillsV2'

import DrillsConfig from '@/mixins/drillsv2/Config'
import DrillTransition from '@/mixins/drillsv2/DrillTransition'
import DrillWebApi from '@/mixins/drillsv2/WebApi'
import DrillHistoryApi from '@/mixins/drillsv2/HistoryApi'

@Component({
  components: {
    Title,
    ResultGuide,
    PredictedScoreBar,
    Transition,
    Correct,
  },
})
export default class DrillResult extends Mixins(DrillWebApi, DrillHistoryApi, DrillTransition) {
  // 読み込み完了フラグ
  private loadComplete = false

  private drillModules = DrillsConfig.drillModules

  // 表示するモジュールの切り替え
  private get activeModules() {
    // 授業モードで判定
    const classModeCode = this.$store.getters['drillsV2/classModeCode']
    if (classModeCode == 'AI') {
      // AI学習時は理解度を表示する
      return this.drillModules.RESULT_ALL_PREDICTED_SCORE
    }

    // 該当しない場合は理解度表示なしの結果
    return this.drillModules.RESULT_ALL
  }

  protected async mounted(): Promise<any> {
    Vue.prototype.$loading.start()

    Vue.prototype.$logger.info('-- DrillResult V2 mounted')

    // 読み込み完了フラグを未完了に変更
    this.$store.commit('drillsV2/setProcessed', false)

    // ページタイプ設定
    this.$store.commit('drillsV2/setPageType', PAGE_TYPE.RESULT_ALL)

    const resultDrill = await this.getResultDrill(this.resultDrillId)

    // 授業モードと授業種別を保持
    this.$store.commit('drillsV2/setClassModeCode', resultDrill.classModeCode)
    this.$store.commit('drillsV2/setClassCategoryCode', resultDrill.classCategoryCode)

    // 表示用の情報を取得し、ストアに保持
    const sProblemIds = resultDrill.resultDrillPages.map((resultDrillPage) => {
      return resultDrillPage.problems.map((problem) => {
        return problem.sProblemId
      })
    })
    const problems = await this.getProblems(sProblemIds)
    await this.$store.dispatch('drillsV2/setProblems', problems)

    // 正誤情報をストアに保持
    const resultProblems = resultDrill.resultDrillPages.map((resultDrillPage) => {
      return resultDrillPage.problems
    })
    await this.$store.dispatch('drillsV2/setProblemCorrects', resultProblems)
    // 動画視聴状況を登録
    await this.$store.dispatch('drillsV2/setMoviePlayed', resultProblems)

    // その他学習結果情報をストアに保持
    await this.$store.dispatch('drillsV2/setResultDrill', resultDrill)

    // 読み込み完了フラグを設定
    this.$store.commit('drillsV2/setProcessed', true)
    this.loadComplete = true

    Vue.prototype.$loading.complete()
  }
}
